import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import {
  setChannelData,
  setRegionData,
  setStoreIdData,
  setStyleData,
} from '../features/analytics/analyticsSlice'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import InfoIcon from '../components/core/InfoIcon'
import PolaroidPreview from '../components/PolaroidPreview'
import { TitleEdit } from '../components/core/TitleEdit'
import { WizardStepperFooter } from '../components/WizardStepperFooter'
import { colorPalette } from '../styleVariables'
import { getAssetImage } from '../libs/utils'
import { setExperienceCreatedBy } from '../features/experienceCreatedBy/experienceCreatedBySlice'
import { setExperienceName } from '../features/experienceName/experienceNameSlice'
import { setStoreLogo } from '../features/store/storeSlice'
import { setStyleData as setStyleVMData } from '../features/style/styleSlice'

const availableStyles = {
  m4c: {
    styleName: 'No logo template',
    styleLogo: '',
    styleClass: '', // classes that use into app.module.css
  },
  oo: {
    styleName: 'Oakley',
    styleLogo: getAssetImage(`stores/oo.svg`),
    styleClass: 'oo',
  },
  sgh: {
    styleName: 'Sunglasshut',
    styleLogo: getAssetImage(`stores/sgh.svg`),
    styleClass: 'sgh',
  },
  rb: {
    styleName: 'Rayban',
    styleLogo: getAssetImage(`stores/rb.svg`),
    styleClass: 'rb',
  },
  sv: {
    styleName: 'Salmoiraghi & Vigano',
    styleLogo: getAssetImage(`stores/sv.svg`),
    styleClass: 'sv',
  },
  to: {
    styleName: 'Target Optical',
    styleLogo: getAssetImage(`stores/to.svg`),
    styleClass: 'to',
  },
  opsm: {
    styleName: 'Opsm',
    styleLogo: getAssetImage(`stores/opsm.svg`),
    styleClass: 'opsm',
  },
  lc: {
    styleName: 'LensCrafters',
    styleLogo: getAssetImage(`stores/lc.svg`),
    styleClass: 'lc',
  },
  go: {
    styleName: 'Grand Optical',
    styleLogo: getAssetImage(`stores/grand-optical.svg`),
    styleClass: '',
  },
}

const availableRegions: { [key: string]: string } = {
  emea: 'EMEA',
  us: 'US',
  latam: 'LATAM',
  anz: 'ANZ',
  china: 'CHINA',
  sea: 'SEA',
  fareast: 'FAR EAST',
}

const availableChannel: { [key: string]: string } = {
  retail: 'In store - Retail',
  wholesale: 'In store - Wholesale',
  channel: 'In store - All channel',
  billboard: 'OOH/Billboard',
  newsletters: 'EDM/Newsletters',
}

const useStyles = makeStyles(() =>
  createStyles({
    outer: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '70vw',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    inner: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '20vw',
      width: '100%',
      margin: '0 auto 2rem',
      alignItems: 'center',
    },
    input: {
      width: '100%',
      marginBottom: '4rem',
    },
    menuItem: {
      fontSize: '1.35rem',
    },
    select: {
      width: '100%',
      marginBottom: '5rem',
    },
    spacing: {
      marginTop: '1.6rem',
    },
    infoIconContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    infoIcon: {
      color: colorPalette.blue,
      width: '15px',
      height: '15px',
    },
    infoIconButton: {
      alignSelf: 'flex-start',
      margin: '-0.8rem',
      padding: '0.7rem',
    },
    tooltip: {
      color: colorPalette.white,
      fontsize: ' 0.625rem',
      maxWidth: ' 230px',
      fontFamily: ' Roboto,serif',
      padding: '0.2rem',
      fontWeight: 500,
      lineHeight: ' 1.4em',
      backgroundColor: 'rgba(97, 97, 97, 1)',
      borderRadius: '8px',
    },
    kidsText: {
      position: 'absolute',
      top: '50%',
      left: '0',
      transform: 'translateY(-50%)',
      width: '35%',
    },
  })
)

export const EditExperienceInfoPage: React.FC = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const {
    style,
    name: experienceName,
    isEditExp,
    region,
    channel,
  } = useSelector(
    (state) => ({
      name: state.vmData.name || '',
      isEditExp: !!state.listing.edittableUuid || false,
      style: state.vmData.analytics.storeCompany || '',
      region: state.vmData.analytics.storeRegion || '',
      channel: state.vmData.analytics.pageBrand || '',
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(setExperienceCreatedBy(localStorage.getItem('userName')))
  }, [dispatch])

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const currentTarget = event.currentTarget as HTMLSelectElement
    dispatch(setStyleData(event.target.value as string))
    const style = Object.values(availableStyles).find(
      ({ styleName }) => styleName === event.target.value
    )
    style && dispatch(setStyleVMData(style.styleClass))

    dispatch(setStoreLogo(currentTarget.getAttribute('data-logo') as string))
  }

  const handleRegionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setRegionData(event.target.value as string))
  }
  const handleChannelChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    dispatch(setChannelData(event.target.value as string))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newExperienceName = e.target.value
    dispatch(setExperienceName(newExperienceName))
    dispatch(setStoreIdData(newExperienceName))
  }

  const isStepValid = !!(
    style &&
    style.length &&
    experienceName.length &&
    region.length
  )
  const currentLogo = useSelector((state) => state.vmData.store.logo)

  return (
    <>
      <Box className={styles.outer}>
        <Box className={styles.inner}>
          <Box className={styles.infoIconContainer}>
            <TitleEdit title="Name" />
            <Tooltip
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{ display: 'flex', flexDirection: 'row' }}
                  placement="right"
                >
                  <div className={styles.tooltip}>
                    <Typography color="inherit">
                      It will not be possibile to modify the
                      <b> Name</b> of the experience once saved
                    </Typography>
                  </div>
                </Popper>
              )}
              title="name"
            >
              <IconButton className={styles.infoIconButton}>
                <InfoIcon className={styles.infoIcon} />
              </IconButton>
            </Tooltip>
          </Box>
          <TextField
            id="name"
            label="e.g. Experience 1"
            onChange={handleChange}
            className={styles.input}
            value={experienceName}
            disabled={isEditExp}
          />
          <div className={styles.spacing} />
          <Box className={styles.infoIconContainer}>
            <TitleEdit title="Usage" />
            <Tooltip
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{ display: 'flex', flexDirection: 'row' }}
                  placement="right"
                >
                  <div className={styles.tooltip} style={{ maxWidth: '300px' }}>
                    <Typography color="inherit">
                      This field is only required for analytics purposes, it
                      will not affect the scope of the experience
                    </Typography>
                  </div>
                </Popper>
              )}
              title="usage"
            >
              <IconButton className={styles.infoIconButton}>
                <InfoIcon className={styles.infoIcon} />
              </IconButton>
            </Tooltip>
          </Box>
          <FormControl className={styles.select} variant="outlined">
            <InputLabel id="select-style">Select</InputLabel>
            <Select
              labelId="select-style"
              id="select-style"
              value={channel}
              onChange={handleChannelChange}
              label="Select"
            >
              {Object.entries(availableChannel).map(([key, value]) => (
                <MenuItem key={key} value={value} className={styles.menuItem}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box className={styles.inner}>
          <Box className={styles.infoIconContainer}>
            <TitleEdit title="Region" />
            <Tooltip
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{ display: 'flex', flexDirection: 'row' }}
                  placement="right"
                >
                  <div className={styles.tooltip} style={{ maxWidth: '300px' }}>
                    <Typography color="inherit">
                      The Region selected will determine the Privacy Policy
                      shown during the experience
                    </Typography>
                  </div>
                </Popper>
              )}
              title="region"
            >
              <IconButton className={styles.infoIconButton}>
                <InfoIcon className={styles.infoIcon} />
              </IconButton>
            </Tooltip>
          </Box>
          <FormControl className={styles.select} variant="outlined">
            <InputLabel id="select-style">Select</InputLabel>
            <Select
              labelId="select-style"
              id="select-style"
              value={region}
              onChange={handleRegionChange}
              label="Select"
            >
              {Object.entries(availableRegions).map(([key, value]) => (
                <MenuItem key={key} value={value} className={styles.menuItem}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box className={styles.infoIconContainer}>
            <TitleEdit title="Template Style" />
            <Tooltip
              PopperComponent={(props) => (
                <Popper
                  {...props}
                  style={{ display: 'flex', flexDirection: 'row' }}
                  placement="right"
                >
                  <div className={styles.tooltip} style={{ maxWidth: '400px' }}>
                    <Typography color="inherit">
                      The logo of the <b>Banner</b> selected will appear on the
                      Polaroid. If you select <i>No logo template</i>, the logo
                      of the <b>Brand</b> worn by the user in the selfie will
                      appear
                    </Typography>
                  </div>
                </Popper>
              )}
              title="tempalate style"
            >
              <IconButton className={styles.infoIconButton}>
                <InfoIcon className={styles.infoIcon} />
              </IconButton>
            </Tooltip>
          </Box>
          <FormControl className={styles.select} variant="outlined">
            <InputLabel id="select-style">Select</InputLabel>
            <Select
              labelId="select-style"
              id="select-style"
              onChange={handleSelectChange}
              label="Select"
              value={style}
            >
              {Object.entries(availableStyles).map(([key, availableStyle]) => (
                <MenuItem
                  key={key}
                  value={availableStyle.styleName}
                  className={styles.menuItem}
                  data-logo={availableStyle.styleLogo}
                >
                  {availableStyle.styleName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <PolaroidPreview style={style} currentLogo={currentLogo || ''} />
      </Box>

      <WizardStepperFooter isValid={isStepValid}>
        <Typography className={styles.kidsText}>
          Please note, that due to US regulations, it is not possible to direct
          Virtual Mirror experiences to kids (under 13 years old) in NA region,
          without parental consent
        </Typography>
      </WizardStepperFooter>
    </>
  )
}
